export const dailyEmmissions = [
  [0, 1443003.349],
  [1, 1354084.145],
  [2, 1354084.145],
  [3, 1273280.393],
  [4, 1273280.393],
  [5, 1273280.393],
  [6, 1273280.393],
  [7, 1258246.192],
  [8, 1258246.192],
  [9, 1246619.519],
  [10, 1246619.519],
  [11, 1245423.894],
  [12, 1245329.891],
  [13, 1214959.168],
  [14, 794827.3416],
  [15, 792419.9855],
  [16, 767313.4958],
  [17, 718116.7622],
  [18, 697505.0964],
  [19, 686565.8539],
  [20, 685330.9963],
  [21, 647980.3947],
  [22, 630163.1737],
  [23, 628845.7743],
  [24, 621363.4239],
  [25, 606231.3714],
  [26, 589613.4479],
  [27, 582204.036],
  [28, 531213.034],
  [29, 514411.6021],
  [30, 500589.5515],
  [31, 498384.2218],
  [32, 495905.9735],
  [33, 493742.0447],
  [34, 485312.0814],
  [35, 476434.9308],
  [36, 459805.0144],
  [37, 454031.1077],
  [38, 448239.8596],
  [39, 441737.6488],
  [40, 413841.7706],
  [41, 408072.1067],
  [42, 377185.572],
  [43, 358508.339],
  [44, 352292.1605],
  [45, 344954.8882],
  [46, 338076.366],
  [47, 336656.4072],
  [48, 329749.5316],
  [49, 317265.7298],
  [50, 310316.9692],
  [51, 306859.2951],
  [52, 304510.3321],
  [53, 303181.07],
  [54, 286894.6047],
  [55, 284592.0026],
  [56, 251462.4336],
  [57, 247846.4532],
  [58, 241405.8246],
  [59, 239039.2594],
  [60, 238542.0873],
  [61, 237044.4461],
  [62, 233234.7337],
  [63, 229707.8518],
  [64, 228030.8839],
  [65, 220130.9216],
  [66, 218737.6345],
  [67, 216761.9682],
  [68, 216471.2475],
  [69, 208238.4326],
  [70, 203210.0409],
  [71, 202800.1009],
  [72, 202204.4751],
  [73, 201411.902],
  [74, 200328.663],
  [75, 194523.9537],
  [76, 188401.6707],
  [77, 187494.4438],
  [78, 185070.1821],
  [79, 183499.3463],
  [80, 176556.8081],
  [81, 174973.592],
  [82, 174194.7161],
  [83, 170746.9894],
  [84, 168380.1815],
  [85, 165548.2003],
  [86, 164753.7574],
  [87, 163642.2212],
  [88, 160660.1442],
  [89, 158203.338],
  [90, 110403.2408],
  [91, 105731.4425],
  [92, 104201.5841],
  [93, 102116.158],
  [94, 101585.1832],
  [95, 97750.99637],
  [96, 92996.16316],
  [97, 89931.72218],
  [98, 89461.94423],
  [99, 83638.11885],
  [100, 83485.33664],
  [101, 83339.77121],
  [102, 83025.72344],
  [103, 75210.70219],
  [104, 75210.70219],
  [105, 75139.81647],
  [106, 74513.2758],
  [107, 74513.2758],
  [108, 74040.20347],
  [109, 73860.5217],
  [110, 73724.01853],
  [111, 73629.99487],
  [112, 72714.20356],
  [113, 72714.20356],
  [114, 72308.32998],
  [115, 72308.32998],
  [116, 72284.10154],
  [117, 72088.02586],
  [118, 72035.93642],
  [119, 72035.93642],
  [120, 72035.93642],
  [121, 71350.19364],
  [122, 70760.07453],
  [123, 69890.05337],
  [124, 69890.05337],
  [125, 69498.4645],
  [126, 69218.32468],
  [127, 69091.92074],
  [128, 68200.50575],
  [129, 68200.50575],
  [130, 68195.78004],
  [131, 68195.78004],
  [132, 68103.4047],
  [133, 67748.72165],
  [134, 67748.72165],
  [135, 67649.42715],
  [136, 67649.42715],
  [137, 67596.26629],
  [138, 67511.72085],
  [139, 67511.72085],
  [140, 67398.05841],
  [141, 67365.32771],
  [142, 67299.49137],
  [143, 67281.15228],
  [144, 67281.15228],
  [145, 66819.10901],
  [146, 66802.09254],
  [147, 66616.25584],
  [148, 66616.25584],
  [149, 66548.04788],
  [150, 65924.71201],
  [151, 65914.68471],
  [152, 65776.13837],
  [153, 65776.13837],
  [154, 65565.60599],
  [155, 65565.60599],
  [156, 65533.19376],
  [157, 65201.20007],
  [158, 64738.2948],
  [159, 64738.2948],
  [160, 64712.02474],
  [161, 64712.02474],
  [162, 64712.02474],
  [163, 64712.02474],
  [164, 64712.02474],
  [165, 64712.02474],
  [166, 63796.45764],
  [167, 63796.45764],
  [168, 63268.43018],
  [169, 62688.94289],
  [170, 62688.94289],
  [171, 62155.59392],
  [172, 60775.39252],
  [173, 60775.39252],
  [174, 60519.13214],
  [175, 60426.80327],
  [176, 60426.80327],
  [177, 60397.87695],
  [178, 60353.15378],
  [179, 60278.18394],
  [180, 59135.8245],
  [181, 59135.8245],
  [182, 57945.29587],
  [183, 57775.53031],
  [184, 57775.53031],
  [185, 57775.53031],
  [186, 57730.91198],
  [187, 57708.79731],
  [188, 57708.79731],
  [189, 57620.90273],
  [190, 57620.90273],
  [191, 55871.32918],
  [192, 55840.59183],
  [193, 55840.59183],
  [194, 55840.59183],
  [195, 55840.59183],
  [196, 55815.59482],
  [197, 55815.59482],
  [198, 55815.59482],
  [199, 55185.96958],
  [200, 53592.87805],
  [201, 52409.56711],
  [202, 51848.13654],
  [203, 51848.13654],
  [204, 51783.45183],
  [205, 51783.45183],
  [206, 51421.3646],
  [207, 51421.3646],
  [208, 51421.3646],
  [209, 51421.3646],
  [210, 51409.02692],
  [211, 51224.21542],
  [212, 45815.54407],
  [213, 45815.54407],
  [214, 45759.48011],
  [215, 45759.05609],
  [216, 45759.05609],
  [217, 45654.97726],
  [218, 45629.68541],
  [219, 45629.68541],
  [220, 45554.13508],
  [221, 45503.41297],
  [222, 45503.41297],
  [223, 45503.41297],
  [224, 45476.08089],
  [225, 45459.52453],
  [226, 45205.42618],
  [227, 45205.42618],
  [228, 45183.64392],
  [229, 45183.64392],
  [230, 45183.64392],
  [231, 45183.64392],
  [232, 45183.64392],
  [233, 45183.64392],
  [234, 45171.87476],
  [235, 45171.87476],
  [236, 45148.66185],
  [237, 45148.66185],
  [238, 45148.66185],
  [239, 45114.64974],
  [240, 45076.64018],
  [241, 45065.95041],
  [242, 45065.95041],
  [243, 45043.47607],
  [244, 44890.74784],
  [245, 44834.98921],
  [246, 44834.98921],
  [247, 44834.98921],
  [248, 44834.98921],
  [249, 44834.98921],
  [250, 44569.24199],
  [251, 44569.24199],
  [252, 44569.24199],
  [253, 44350.98341],
  [254, 44121.95951],
  [255, 44089.52813],
  [256, 44089.52813],
  [257, 43893.28667],
  [258, 43893.28667],
  [259, 43893.28667],
  [260, 43606.81022],
  [261, 43606.81022],
  [262, 43421.92156],
  [263, 43421.92156],
  [264, 43421.92156],
  [265, 43421.92156],
  [266, 43287.67397],
  [267, 43287.67397],
  [268, 43287.67397],
  [269, 43287.67397],
  [270, 43287.67397],
  [271, 43287.67397],
  [272, 43252.61361],
  [273, 43152.35953],
  [274, 43149.60519],
  [275, 43149.60519],
  [276, 43038.39074],
  [277, 42888.77603],
  [278, 42841.37622],
  [279, 42741.78755],
  [280, 42741.78755],
  [281, 42703.42126],
  [282, 42703.42126],
  [283, 42666.79462],
  [284, 42653.68038],
  [285, 42653.68038],
  [286, 42653.68038],
  [287, 42647.82524],
  [288, 42600.44503],
  [289, 42600.44503],
  [290, 42600.44503],
  [291, 42600.44503],
  [292, 42600.44503],
  [293, 42600.44503],
  [294, 42600.44503],
  [295, 42277.07812],
  [296, 42277.07812],
  [297, 42277.07812],
  [298, 42277.07812],
  [299, 42243.78541],
  [300, 42232.74494],
  [301, 42232.74494],
  [302, 42232.74494],
  [303, 42218.94516],
  [304, 42218.94516],
  [305, 42218.94516],
  [306, 42148.43334],
  [307, 42148.43334],
  [308, 42148.43334],
  [309, 42144.33562],
  [310, 42144.33562],
  [311, 42144.33562],
  [312, 42144.33562],
  [313, 42139.57405],
  [314, 41822.578],
  [315, 41822.578],
  [316, 41679.41708],
  [317, 41679.41708],
  [318, 41668.06458],
  [319, 41667.44108],
  [320, 41667.44108],
  [321, 41667.44108],
  [322, 41667.44108],
  [323, 41667.44108],
  [324, 41667.44108],
  [325, 41667.44108],
  [326, 41644.3018],
  [327, 41468.24992],
  [328, 41468.24992],
  [329, 41081.2341],
  [330, 41081.2341],
  [331, 41081.2341],
  [332, 41081.2341],
  [333, 41060.15438],
  [334, 41060.15438],
  [335, 41055.45729],
  [336, 41055.45729],
  [337, 40945.94397],
  [338, 40945.94397],
  [339, 40945.94397],
  [340, 40937.87496],
  [341, 40937.87496],
  [342, 40937.87496],
  [343, 40929.97446],
  [344, 40929.97446],
  [345, 40929.97446],
  [346, 40929.97446],
  [347, 40929.97446],
  [348, 40929.97446],
  [349, 40929.97446],
  [350, 40929.97446],
  [351, 40929.97446],
  [352, 40929.97446],
  [353, 40877.77128],
  [354, 40867.47765],
  [355, 40440.15031],
  [356, 40440.15031],
  [357, 40440.15031],
  [358, 40440.15031],
  [359, 40440.15031],
  [360, 40359.45968],
  [361, 40359.45968],
  [362, 40359.45968],
  [363, 40359.45968],
  [364, 40359.34608],
  [365, 40125.49766],
  [366, 40103.75201],
  [367, 40103.75201],
  [368, 40103.75201],
  [369, 40103.75201],
  [370, 40103.75201],
  [371, 40103.75201],
  [372, 40103.75201],
  [373, 40103.75201],
  [374, 40103.75201],
  [375, 40103.75201],
  [376, 40103.75201],
  [377, 40103.75201],
  [378, 40103.75201],
  [379, 40103.75201],
  [380, 40103.75201],
  [381, 40103.75201],
  [382, 40103.75201],
  [383, 39818.66462],
  [384, 39818.66462],
  [385, 39818.66462],
  [386, 39818.66462],
  [387, 39818.66462],
  [388, 39818.66462],
  [389, 39818.66462],
  [390, 39818.66462],
  [391, 39809.13046],
  [392, 39809.13046],
  [393, 39809.13046],
  [394, 39809.13046],
  [395, 39637.17815],
  [396, 39637.17815],
  [397, 39637.17815],
  [398, 39637.17815],
  [399, 39581.91709],
  [400, 39581.91709],
  [401, 39581.91709],
  [402, 39565.44913],
  [403, 39565.44913],
  [404, 39565.44913],
  [405, 39360.8453],
  [406, 39360.8453],
  [407, 39360.8453],
  [408, 39360.8453],
  [409, 39360.8453],
  [410, 39360.8453],
  [411, 39360.8453],
  [412, 39360.8453],
  [413, 39360.8453],
  [414, 39360.8453],
  [415, 39353.18946],
  [416, 39353.18946],
  [417, 39353.18946],
  [418, 39353.18946],
  [419, 39348.23163],
  [420, 39157.82471],
  [421, 39156.19087],
  [422, 39156.19087],
  [423, 39148.43262],
  [424, 39148.43262],
  [425, 39148.43262],
  [426, 39148.43262],
  [427, 39148.43262],
  [428, 39148.43262],
  [429, 39148.43262],
  [430, 39148.43262],
  [431, 39148.43262],
  [432, 39148.43262],
  [433, 39148.43262],
  [434, 39045.41927],
  [435, 39030.19782],
  [436, 39019.596],
  [437, 39019.596],
  [438, 39019.596],
  [439, 39019.596],
  [440, 39019.596],
  [441, 39019.596],
  [442, 39019.596],
  [443, 39019.596],
  [444, 39019.596],
  [445, 39003.55297],
  [446, 39003.55297],
  [447, 39003.55297],
  [448, 39003.55297],
  [449, 39003.55297],
  [450, 39003.55297],
  [451, 39003.55297],
  [452, 39003.55297],
  [453, 39003.55297],
  [454, 39003.55297],
  [455, 39003.55297],
  [456, 39003.55297],
  [457, 39003.55297],
  [458, 38997.22126],
  [459, 38838.65371],
  [460, 38648.7561],
  [461, 38648.7561],
  [462, 38648.7561],
  [463, 38648.7561],
  [464, 37773.61232],
  [465, 37773.61232],
  [466, 37764.5511],
  [467, 37764.5511],
  [468, 37764.5511],
  [469, 37764.5511],
  [470, 37764.5511],
  [471, 37170.12336],
  [472, 37170.12336],
  [473, 37170.12336],
  [474, 37170.12336],
  [475, 37170.12336],
  [476, 37170.12336],
  [477, 37170.12336],
  [478, 37170.12336],
  [479, 37170.12336],
  [480, 37170.12336],
  [481, 37170.12336],
  [482, 36947.6874],
  [483, 36937.47479],
  [484, 36931.52464],
  [485, 36931.52464],
  [486, 36931.52464],
  [487, 36931.52464],
  [488, 36931.52464],
  [489, 36931.52464],
  [490, 36931.52464],
  [491, 36905.41176],
  [492, 36905.41176],
  [493, 36905.41176],
  [494, 36905.41176],
  [495, 36905.41176],
  [496, 36905.41176],
  [497, 36905.41176],
  [498, 36905.41176],
  [499, 36249.87015],
  [500, 36249.87015],
  [501, 36246.56875],
  [502, 36246.56875],
  [503, 36246.56875],
  [504, 36246.56875],
  [505, 36246.56875],
  [506, 36246.56875],
  [507, 36232.21322],
  [508, 36232.21322],
  [509, 36232.21322],
  [510, 36232.21322],
  [511, 36232.21322],
  [512, 36232.21322],
  [513, 36232.21322],
  [514, 36232.21322],
  [515, 36220.16173],
  [516, 36220.16173],
  [517, 35988.02736],
  [518, 35958.26933],
  [519, 35958.26933],
  [520, 35958.26933],
  [521, 35720.28018],
  [522, 35720.28018],
  [523, 35720.28018],
  [524, 35716.7119],
  [525, 34560.85326],
  [526, 34560.85326],
  [527, 34560.85326],
  [528, 34560.85326],
  [529, 34560.85326],
  [530, 34560.85326],
  [531, 34560.85326],
  [532, 34560.85326],
  [533, 34560.85326],
  [534, 34491.2265],
  [535, 34491.2265],
  [536, 34491.2265],
  [537, 34491.2265],
  [538, 34491.2265],
  [539, 34477.68437],
  [540, 34477.68437],
  [541, 34477.68437],
  [542, 34477.68437],
  [543, 34477.68437],
  [544, 34477.68437],
  [545, 34477.68437],
  [546, 34477.68437],
  [547, 34466.68632],
  [548, 34466.68632],
  [549, 34466.68632],
  [550, 34466.68632],
  [551, 34466.68632],
  [552, 34452.08462],
  [553, 34452.08462],
  [554, 34452.08462],
  [555, 34452.08462],
  [556, 34452.08462],
  [557, 34452.08462],
  [558, 34452.08462],
  [559, 34244.16679],
  [560, 34244.16679],
  [561, 34244.16679],
  [562, 34127.46804],
  [563, 34127.46804],
  [564, 34127.46804],
  [565, 34127.46804],
  [566, 34127.46804],
  [567, 34127.46804],
  [568, 34127.46804],
  [569, 34127.46804],
  [570, 34127.46804],
  [571, 34127.46804],
  [572, 34054.51945],
  [573, 34054.51945],
  [574, 34054.51945],
  [575, 34054.51945],
  [576, 34054.51945],
  [577, 34054.51945],
  [578, 34054.51945],
  [579, 34054.51945],
  [580, 34054.51945],
  [581, 34054.51945],
  [582, 34054.51945],
  [583, 34031.80356],
  [584, 33940.49238],
  [585, 33940.49238],
  [586, 33940.49238],
  [587, 33940.49238],
  [588, 33940.49238],
  [589, 33940.49238],
  [590, 33925.21177],
  [591, 33925.21177],
  [592, 33925.21177],
  [593, 33925.21177],
  [594, 33902.80736],
  [595, 33902.80736],
  [596, 33868.07114],
  [597, 33868.07114],
  [598, 33852.83449],
  [599, 33852.83449],
  [600, 33852.83449],
  [601, 33852.83449],
  [602, 33852.83449],
  [603, 33852.83449],
  [604, 33852.83449],
  [605, 33852.83449],
  [606, 33852.83449],
  [607, 33757.87716],
  [608, 33757.87716],
  [609, 33745.64209],
  [610, 33745.64209],
  [611, 33745.64209],
  [612, 33745.64209],
  [613, 33745.64209],
  [614, 33745.57468],
  [615, 33745.57468],
  [616, 33700.56217],
  [617, 33700.56217],
  [618, 33700.56217],
  [619, 33700.56217],
  [620, 33700.56217],
  [621, 33700.56217],
  [622, 33700.56217],
  [623, 33700.56217],
  [624, 33700.56217],
  [625, 33700.56217],
  [626, 33700.56217],
  [627, 33700.56217],
  [628, 33700.56217],
  [629, 33700.56217],
  [630, 33700.56217],
  [631, 33700.56217],
  [632, 33700.56217],
  [633, 33699.60842],
  [634, 33699.60842],
  [635, 33699.60842],
  [636, 33699.60842],
  [637, 33699.60842],
  [638, 33698.31218],
  [639, 33695.46249],
  [640, 33695.46249],
  [641, 33695.46249],
  [642, 33695.46249],
  [643, 33695.46249],
  [644, 33695.46249],
  [645, 33695.46249],
  [646, 33695.46249],
  [647, 33695.46249],
  [648, 33695.46249],
  [649, 33695.46249],
  [650, 33670.01018],
  [651, 33670.01018],
  [652, 33670.01018],
  [653, 33670.01018],
  [654, 33670.01018],
  [655, 33670.01018],
  [656, 33670.01018],
  [657, 33670.01018],
  [658, 33598.37469],
  [659, 33579.85301],
  [660, 33579.85301],
  [661, 33579.85301],
  [662, 33579.85301],
  [663, 33579.85301],
  [664, 33579.85301],
  [665, 33579.85301],
  [666, 33579.85301],
  [667, 33579.85301],
  [668, 33579.85301],
  [669, 33465.19657],
  [670, 33341.70844],
  [671, 33274.10259],
  [672, 33274.10259],
  [673, 33274.10259],
  [674, 33274.10259],
  [675, 33274.10259],
  [676, 33274.10259],
  [677, 33274.10259],
  [678, 33274.10259],
  [679, 33274.10259],
  [680, 33274.10259],
  [681, 33274.10259],
  [682, 33274.10259],
  [683, 32818.74368],
  [684, 32818.74368],
  [685, 32818.74368],
  [686, 32818.74368],
  [687, 32818.74368],
  [688, 32818.74368],
  [689, 32815.39372],
  [690, 32815.39372],
  [691, 32815.39372],
  [692, 32732.54709],
  [693, 32732.54709],
  [694, 32729.8402],
  [695, 32625.37674],
  [696, 32625.37674],
  [697, 32625.37674],
  [698, 32625.37674],
  [699, 32614.72698],
  [700, 32614.72698],
  [701, 32614.72698],
  [702, 32614.72698],
  [703, 32614.72698],
  [704, 32613.25655],
  [705, 32613.25655],
  [706, 32613.25655],
  [707, 32613.25655],
  [708, 32613.25655],
  [709, 32613.25655],
  [710, 32613.25655],
  [711, 32583.47073],
  [712, 32583.47073],
  [713, 32583.47073],
  [714, 32583.47073],
  [715, 32583.47073],
  [716, 32576.09846],
  [717, 32576.09846],
  [718, 32576.09846],
  [719, 32576.09846],
  [720, 32576.09846],
  [721, 32576.09846],
  [722, 32576.09846],
  [723, 32576.09846],
  [724, 32576.09846],
  [725, 32576.09846],
  [726, 32576.09846],
  [727, 32576.09846],
  [728, 32576.09846],
  [729, 32576.09846],
  [730, 30889.37104],
  [731, 30832.7272],
  [732, 30832.7272],
  [733, 30832.7272],
  [734, 30832.7272],
  [735, 30830.58861],
  [736, 30830.58861],
  [737, 30830.58861],
  [738, 27549.75287],
  [739, 27549.75287],
  [740, 27549.75287],
  [741, 27526.29029],
  [742, 27526.29029],
  [743, 27526.29029],
  [744, 27526.29029],
  [745, 27518.75535],
  [746, 27518.75535],
  [747, 27518.75535],
  [748, 27518.75535],
  [749, 27518.75535],
  [750, 27518.75535],
  [751, 27518.75535],
  [752, 27518.75535],
  [753, 27511.94518],
  [754, 27511.94518],
  [755, 27511.94518],
  [756, 27511.94518],
  [757, 27511.94518],
  [758, 27511.94518],
  [759, 27511.94518],
  [760, 27511.94518],
  [761, 27511.94518],
  [762, 27511.94518],
  [763, 27511.94518],
  [764, 27511.94518],
  [765, 27511.94518],
  [766, 27511.94518],
  [767, 27511.94518],
  [768, 27511.94518],
  [769, 27511.94518],
  [770, 27511.94518],
  [771, 27511.94518],
  [772, 27497.84263],
  [773, 27497.84263],
  [774, 27484.05819],
  [775, 27196.52449],
  [776, 27196.52449],
  [777, 27196.52449],
  [778, 27196.52449],
  [779, 27196.52449],
  [780, 27196.52449],
  [781, 27196.52449],
  [782, 27196.52449],
  [783, 27196.52449],
  [784, 27196.52449],
  [785, 27196.31366],
  [786, 27111.54354],
  [787, 27111.54354],
  [788, 27111.54354],
  [789, 27111.54354],
  [790, 27111.54354],
  [791, 27111.54354],
  [792, 27089.59968],
  [793, 27089.59968],
  [794, 27089.59968],
  [795, 27089.59968],
  [796, 27089.59968],
  [797, 27089.59968],
  [798, 27089.59968],
  [799, 27081.177],
  [800, 27081.177],
  [801, 27081.177],
  [802, 27081.177],
  [803, 27081.177],
  [804, 27081.177],
  [805, 26888.80681],
  [806, 26888.80681],
  [807, 26888.80681],
  [808, 26888.80681],
  [809, 26888.80681],
  [810, 26888.80681],
  [811, 26888.80681],
  [812, 26840.37284],
  [813, 26840.37284],
  [814, 26840.37284],
  [815, 26840.37284],
  [816, 26840.37284],
  [817, 26840.37284],
  [818, 26840.37284],
  [819, 26840.37284],
  [820, 26840.37284],
  [821, 26840.37284],
  [822, 26840.37284],
  [823, 26057.89796],
  [824, 26057.89796],
  [825, 26047.10714],
  [826, 26047.10714],
  [827, 26043.35641],
  [828, 26043.35641],
  [829, 26043.35641],
  [830, 26043.35641],
  [831, 25981.18337],
  [832, 25981.18337],
  [833, 25981.18337],
  [834, 25981.18337],
  [835, 25981.18337],
  [836, 25977.62212],
  [837, 25977.62212],
  [838, 25977.62212],
  [839, 25949.79733],
  [840, 25949.7087],
  [841, 25949.7087],
  [842, 25941.11655],
  [843, 25941.11655],
  [844, 25941.11655],
  [845, 25941.11655],
  [846, 25941.11655],
  [847, 25941.11655],
  [848, 25941.11655],
  [849, 25941.11655],
  [850, 25941.11655],
  [851, 25941.11655],
  [852, 25941.11655],
  [853, 25941.11655],
  [854, 25941.11655],
  [855, 25941.11655],
  [856, 25941.11655],
  [857, 25941.11655],
  [858, 25941.11655],
  [859, 25941.11655],
  [860, 25941.11655],
  [861, 25941.11655],
  [862, 25928.75739],
  [863, 25928.75739],
  [864, 25928.75739],
  [865, 25918.71589],
  [866, 25918.71589],
  [867, 25918.71589],
  [868, 25918.71589],
  [869, 25918.71589],
  [870, 25918.71589],
  [871, 25918.71589],
  [872, 25918.71589],
  [873, 25911.08881],
  [874, 25911.08881],
  [875, 25911.08881],
  [876, 25911.08881],
  [877, 25911.08881],
  [878, 25866.76948],
  [879, 25866.76948],
  [880, 25866.76948],
  [881, 25863.91453],
  [882, 25863.91453],
  [883, 25863.91453],
  [884, 25770.25745],
  [885, 25770.25745],
  [886, 25770.25745],
  [887, 25770.25745],
  [888, 25770.25745],
  [889, 25770.25745],
  [890, 25770.14584],
  [891, 25770.14584],
  [892, 25770.14584],
  [893, 25770.14584],
  [894, 25770.14584],
  [895, 25770.14584],
  [896, 25769.3612],
  [897, 25769.3612],
  [898, 25769.3612],
  [899, 25764.75857],
  [900, 25764.75857],
  [901, 25764.75857],
  [902, 25764.75857],
  [903, 25707.05177],
  [904, 25707.05177],
  [905, 25707.05177],
  [906, 25707.05177],
  [907, 25707.05177],
  [908, 25707.05177],
  [909, 25707.05177],
  [910, 25707.05177],
  [911, 25707.05177],
  [912, 25633.14739],
  [913, 25633.14739],
  [914, 25633.14739],
  [915, 25633.14739],
  [916, 25633.14739],
  [917, 25633.14739],
  [918, 25615.12564],
  [919, 25615.12564],
  [920, 25615.12564],
  [921, 25615.12564],
  [922, 25615.12564],
  [923, 25615.12564],
  [924, 25615.12564],
  [925, 25615.12564],
  [926, 25615.12564],
  [927, 25606.49228],
  [928, 25606.49228],
  [929, 25606.49228],
  [930, 25606.49228],
  [931, 25606.49228],
  [932, 25606.49228],
  [933, 25606.49228],
  [934, 25462.57289],
  [935, 25462.57289],
  [936, 25462.57289],
  [937, 25462.57289],
  [938, 25462.57289],
  [939, 25461.85011],
  [940, 25461.85011],
  [941, 25461.85011],
  [942, 25461.85011],
  [943, 25461.85011],
  [944, 25461.85011],
  [945, 25461.85011],
  [946, 25461.85011],
  [947, 25443.93533],
  [948, 25443.93533],
  [949, 25443.93533],
  [950, 25443.93533],
  [951, 25443.93533],
  [952, 25443.93533],
  [953, 25443.93533],
  [954, 25441.17719],
  [955, 25441.17719],
  [956, 25441.17719],
  [957, 25441.17719],
  [958, 25441.17719],
  [959, 25441.17719],
  [960, 25436.5071],
  [961, 25436.5071],
  [962, 25430.93109],
  [963, 25430.55294],
  [964, 25430.55294],
  [965, 25430.55294],
  [966, 25430.55294],
  [967, 25430.55294],
  [968, 25430.55294],
  [969, 25430.55294],
  [970, 25430.55294],
  [971, 25430.55294],
  [972, 25430.55294],
  [973, 25430.55294],
  [974, 25430.55294],
  [975, 25430.55294],
  [976, 25430.55294],
  [977, 25430.55294],
  [978, 25430.55294],
  [979, 25430.55294],
  [980, 25430.55294],
  [981, 25430.55294],
  [982, 25404.88436],
  [983, 25404.88436],
  [984, 25404.88436],
  [985, 25404.88436],
  [986, 25404.88436],
  [987, 25404.88436],
  [988, 25404.88436],
  [989, 25404.88436],
  [990, 25399.14449],
  [991, 25399.14449],
  [992, 25399.14449],
  [993, 25399.14449],
  [994, 24887.37898],
  [995, 24887.37898],
  [996, 24887.37898],
  [997, 24887.37898],
  [998, 24887.37898],
  [999, 24887.37898],
  [1000, 24887.37898],
  [1001, 24887.37898],
  [1002, 24887.37898],
  [1003, 24887.37898],
  [1004, 24819.99633],
  [1005, 24819.99633],
  [1006, 24818.21145],
  [1007, 24818.21145],
  [1008, 24818.21145],
  [1009, 24818.21145],
  [1010, 24818.21145],
  [1011, 24818.21145],
  [1012, 24818.21145],
  [1013, 24818.21145],
  [1014, 24818.21145],
  [1015, 24818.21145],
  [1016, 24818.21145],
  [1017, 24818.21145],
  [1018, 24818.21145],
  [1019, 24818.21145],
  [1020, 24818.21145],
  [1021, 24818.21145],
  [1022, 24818.21145],
  [1023, 24818.21145],
  [1024, 24817.05552],
  [1025, 24817.05552],
  [1026, 24812.34647],
  [1027, 24812.34647],
  [1028, 24812.34647],
  [1029, 24812.34647],
  [1030, 24812.34647],
  [1031, 24812.34647],
  [1032, 24812.34647],
  [1033, 24812.34647],
  [1034, 24812.34647],
  [1035, 24812.34647],
  [1036, 24812.34647],
  [1037, 24812.34647],
  [1038, 24812.34647],
  [1039, 24812.34647],
  [1040, 24812.34647],
  [1041, 24812.34647],
  [1042, 24812.34647],
  [1043, 24812.34647],
  [1044, 24812.34647],
  [1045, 24811.56288],
  [1046, 24769.60722],
  [1047, 24769.60722],
  [1048, 24769.60722],
  [1049, 24769.60722],
  [1050, 24769.60722],
  [1051, 24769.60722],
  [1052, 24769.60722],
  [1053, 24769.60722],
  [1054, 24769.60722],
  [1055, 24769.60722],
  [1056, 24769.60722],
  [1057, 24769.60722],
  [1058, 24769.60722],
  [1059, 24769.60722],
  [1060, 24769.60722],
  [1061, 24769.60722],
  [1062, 24769.60722],
  [1063, 24769.60722],
  [1064, 24769.60722],
  [1065, 24769.60722],
  [1066, 24769.60722],
  [1067, 24769.60722],
  [1068, 24769.60722],
  [1069, 24769.60722],
  [1070, 24738.68439],
  [1071, 24738.68439],
  [1072, 24738.68439],
  [1073, 24738.68439],
  [1074, 24738.68439],
  [1075, 24738.68439],
  [1076, 24738.68439],
  [1077, 24738.68439],
  [1078, 24738.68439],
  [1079, 24738.68439],
  [1080, 24738.68439],
  [1081, 24738.68439],
  [1082, 24738.68439],
  [1083, 24738.68439],
  [1084, 24738.68439],
  [1085, 24730.71622],
  [1086, 24730.71622],
  [1087, 24723.81837],
  [1088, 24723.81837],
  [1089, 24723.81837],
  [1090, 24723.81837],
  [1091, 24723.81837],
  [1092, 24723.81837],
  [1093, 24723.81837],
  [1094, 24723.81837],
  [1095, 24721.55193],
  [1096, 24721.55193],
  [1097, 24700.95369],
  [1098, 24700.95369],
  [1099, 24211.48262],
  [1100, 24211.48262],
  [1101, 24211.48262],
  [1102, 24211.48262],
  [1103, 24211.48262],
  [1104, 23809.10547],
  [1105, 23809.10547],
  [1106, 23809.10547],
  [1107, 23809.10547],
  [1108, 23759.44866],
  [1109, 23759.44866],
  [1110, 23557.90004],
  [1111, 23557.90004],
  [1112, 23557.90004],
  [1113, 23554.14593],
  [1114, 23554.14593],
  [1115, 23554.14593],
  [1116, 23514.61756],
  [1117, 21068.31115],
  [1118, 21068.31115],
  [1119, 21068.31115],
  [1120, 21068.31115],
  [1121, 20758.19537],
  [1122, 20517.2793],
  [1123, 20517.2793],
  [1124, 20517.2793],
  [1125, 20517.2793],
  [1126, 20517.2793],
  [1127, 20517.2793],
  [1128, 20517.2793],
  [1129, 20517.2793],
  [1130, 20517.2793],
  [1131, 20517.2793],
  [1132, 20517.2793],
  [1133, 20517.2793],
  [1134, 20517.2793],
  [1135, 20517.2793],
  [1136, 20517.2793],
  [1137, 20517.2793],
  [1138, 20517.2793],
  [1139, 20517.2793],
  [1140, 20514.39799],
  [1141, 20514.39799],
  [1142, 20514.39799],
  [1143, 20514.39799],
  [1144, 20420.40808],
  [1145, 20420.40808],
  [1146, 20420.40808],
  [1147, 20420.40808],
  [1148, 20420.40808],
  [1149, 20420.40808],
  [1150, 20420.40808],
  [1151, 20420.40808],
  [1152, 20420.40808],
  [1153, 20419.3532],
  [1154, 20419.3532],
  [1155, 20419.3532],
  [1156, 20419.28879],
  [1157, 20419.28879],
  [1158, 20419.28879],
  [1159, 20419.28879],
  [1160, 20419.28879],
  [1161, 20419.28879],
  [1162, 20419.28879],
  [1163, 20419.28879],
  [1164, 20419.28879],
  [1165, 20419.28879],
  [1166, 20419.28879],
  [1167, 20374.13826],
  [1168, 20374.13826],
  [1169, 20374.13826],
  [1170, 20322.92452],
  [1171, 20322.92452],
  [1172, 20322.92452],
  [1173, 20322.92452],
  [1174, 20322.62143],
  [1175, 20322.62143],
  [1176, 20307.95607],
  [1177, 20307.95607],
  [1178, 20307.95607],
  [1179, 20307.4438],
  [1180, 20307.4438],
  [1181, 20303.31889],
  [1182, 20303.31889],
  [1183, 20303.31889],
  [1184, 20303.31889],
  [1185, 20303.31889],
  [1186, 20303.31889],
  [1187, 20303.31889],
  [1188, 20303.31889],
  [1189, 20303.31889],
  [1190, 20303.31889],
  [1191, 20299.85917],
  [1192, 20299.85917],
  [1193, 20299.85917],
  [1194, 20288.16446],
  [1195, 20288.16446],
  [1196, 20288.16446],
  [1197, 20288.16446],
  [1198, 20227.55837],
  [1199, 20227.55837],
  [1200, 20227.55837],
  [1201, 20227.55837],
  [1202, 20227.55837],
  [1203, 20227.55837],
  [1204, 20227.55837],
  [1205, 20227.55837],
  [1206, 20205.8574],
  [1207, 20205.8574],
  [1208, 20205.8574],
  [1209, 20198.7764],
  [1210, 20198.7764],
  [1211, 20194.81453],
  [1212, 20194.81453],
  [1213, 20192.58476],
  [1214, 20185.31845],
  [1215, 20185.31845],
  [1216, 20181.83618],
  [1217, 20165.41641],
  [1218, 20165.41641],
  [1219, 20165.41641],
  [1220, 20165.41641],
  [1221, 20165.00974],
  [1222, 20121.42958],
  [1223, 20121.42958],
  [1224, 20121.42958],
  [1225, 20121.42958],
  [1226, 20121.42958],
  [1227, 20121.42958],
  [1228, 20121.42958],
  [1229, 20121.42958],
  [1230, 20121.42958],
  [1231, 20121.42958],
  [1232, 20114.64015],
  [1233, 20114.64015],
  [1234, 20114.64015],
  [1235, 20091.91774],
  [1236, 20091.91774],
  [1237, 20091.91774],
  [1238, 20091.91774],
  [1239, 20091.91774],
  [1240, 20091.91774],
  [1241, 20091.91774],
  [1242, 20091.91774],
  [1243, 20091.91774],
  [1244, 20091.91774],
  [1245, 20091.91774],
  [1246, 20091.91774],
  [1247, 20091.91774],
  [1248, 20091.91774],
  [1249, 20091.91774],
  [1250, 16568.50672],
  [1251, 16568.50672],
  [1252, 16568.50672],
  [1253, 16568.50672],
  [1254, 16568.50672],
  [1255, 16568.50672],
  [1256, 16566.95934],
  [1257, 16566.95934],
  [1258, 16566.95934],
  [1259, 16566.95934],
  [1260, 16566.95934],
  [1261, 16386.18326],
  [1262, 16386.18326],
  [1263, 16386.18326],
  [1264, 16386.18326],
  [1265, 16386.18326],
  [1266, 16329.00553],
  [1267, 16329.00553],
  [1268, 16329.00553],
  [1269, 16320.16893],
  [1270, 16320.16893],
  [1271, 16320.16893],
  [1272, 16320.16893],
  [1273, 16320.16893],
  [1274, 16320.16893],
  [1275, 16320.16893],
  [1276, 16320.16893],
  [1277, 16320.16893],
  [1278, 15926.77003],
  [1279, 15858.85958],
  [1280, 15858.85958],
  [1281, 15858.85958],
  [1282, 15858.85958],
  [1283, 15858.85958],
  [1284, 15858.85958],
  [1285, 15858.85958],
  [1286, 15858.85958],
  [1287, 15857.7413],
  [1288, 15857.7413],
  [1289, 15857.7413],
  [1290, 15857.7413],
  [1291, 15857.7413],
  [1292, 15857.7413],
  [1293, 15857.7413],
  [1294, 15857.7413],
  [1295, 15847.68815],
  [1296, 15847.68815],
  [1297, 15847.68815],
  [1298, 15837.4845],
  [1299, 15837.4845],
  [1300, 15837.4845],
  [1301, 15802.43821],
  [1302, 15802.43821],
  [1303, 15802.43821],
  [1304, 15802.43821],
  [1305, 15802.43821],
  [1306, 15802.43821],
  [1307, 15802.43821],
  [1308, 15801.24303],
  [1309, 15798.58856],
  [1310, 15798.58856],
  [1311, 15798.58856],
  [1312, 15798.58856],
  [1313, 15798.58856],
  [1314, 15798.58856],
  [1315, 15798.58856],
  [1316, 15798.58856],
  [1317, 15798.58856],
  [1318, 15798.58856],
  [1319, 15798.58856],
  [1320, 15798.58856],
  [1321, 15798.58856],
  [1322, 15798.58856],
  [1323, 15798.58856],
  [1324, 15452.5345],
  [1325, 15452.5345],
  [1326, 15452.5345],
  [1327, 15452.5345],
  [1328, 15452.5345],
  [1329, 15452.5345],
  [1330, 15381.61956],
  [1331, 15381.61956],
  [1332, 15381.61956],
  [1333, 15298.22704],
  [1334, 15298.22704],
  [1335, 15298.22704],
  [1336, 15294.82108],
  [1337, 15282.88288],
  [1338, 15282.88288],
  [1339, 15282.88288],
  [1340, 15282.88288],
  [1341, 15282.88288],
  [1342, 15282.88288],
  [1343, 15282.88288],
  [1344, 15282.88288],
  [1345, 15082.56803],
  [1346, 15082.56803],
  [1347, 15082.56803],
  [1348, 15072.81326],
  [1349, 15072.81326],
  [1350, 15072.81326],
  [1351, 15072.81326],
  [1352, 15072.81326],
  [1353, 15072.81326],
  [1354, 15072.81326],
  [1355, 15072.81326],
  [1356, 15072.81326],
  [1357, 15072.81326],
  [1358, 15072.81326],
  [1359, 15072.81326],
  [1360, 15072.81326],
  [1361, 15072.81326],
  [1362, 15068.58024],
  [1363, 15068.58024],
  [1364, 15068.58024],
  [1365, 15068.58024],
  [1366, 15068.58024],
  [1367, 15068.58024],
  [1368, 15068.58024],
  [1369, 15068.58024],
  [1370, 15068.58024],
  [1371, 15068.58024],
  [1372, 15068.58024],
  [1373, 15068.58024],
  [1374, 15068.58024],
  [1375, 15068.58024],
  [1376, 15068.58024],
  [1377, 15068.58024],
  [1378, 15068.58024],
  [1379, 15068.58024],
  [1380, 15068.58024],
  [1381, 15068.58024],
  [1382, 15068.58024],
  [1383, 15068.58024],
  [1384, 15068.58024],
  [1385, 15068.58024],
  [1386, 15068.58024],
  [1387, 15057.24416],
  [1388, 15057.24416],
  [1389, 15057.24416],
  [1390, 15057.24416],
  [1391, 15057.24416],
  [1392, 15057.24416],
  [1393, 15057.24416],
  [1394, 15057.24416],
  [1395, 15057.24416],
  [1396, 15057.24416],
  [1397, 15057.24416],
  [1398, 15057.24416],
  [1399, 15057.24416],
  [1400, 15057.24416],
  [1401, 15057.24416],
  [1402, 15057.24416],
  [1403, 15057.24416],
  [1404, 15057.24416],
  [1405, 15057.24416],
  [1406, 15057.24416],
  [1407, 15057.24416],
  [1408, 15057.24416],
  [1409, 15042.75271],
  [1410, 15042.75271],
  [1411, 15042.75271],
  [1412, 15042.75271],
  [1413, 15042.75271],
  [1414, 15042.75271],
  [1415, 15042.75271],
  [1416, 15042.75271],
  [1417, 15042.75271],
  [1418, 15042.75271],
  [1419, 15042.75271],
  [1420, 15042.75271],
  [1421, 15042.75271],
  [1422, 15042.75271],
  [1423, 15042.75271],
  [1424, 14986.48586],
  [1425, 14986.48586],
  [1426, 14986.48586],
  [1427, 14986.48586],
  [1428, 14986.48586],
  [1429, 14890.02173],
  [1430, 14890.02173],
  [1431, 14890.02173],
  [1432, 14890.02173],
  [1433, 14030.6459],
  [1434, 14030.6459],
  [1435, 14030.6459],
  [1436, 14030.6459],
  [1437, 14030.6459],
  [1438, 14030.6459],
  [1439, 14030.6459],
  [1440, 14030.6459],
  [1441, 14030.6459],
  [1442, 13603.61744],
  [1443, 13603.61744],
  [1444, 13603.61744],
  [1445, 13603.61744],
  [1446, 13603.61744],
  [1447, 13372.53989],
  [1448, 13337.35035],
  [1449, 13337.35035],
  [1450, 13337.35035],
  [1451, 13336.56331],
  [1452, 13298.00809],
  [1453, 13298.00809],
  [1454, 13298.00809],
  [1455, 13298.00809],
  [1456, 13298.00809],
  [1457, 13298.00809],
  [1458, 13298.00809],
  [1459, 13298.00809],
  [1460, 403.1464427],
  [1461, 403.1464427],
  [1462, 403.1464427],
  [1463, 403.1464427],
  [1464, 403.1464427],
  [1465, 403.1464427],
  [1466, 403.1464427],
  [1467, 403.1464427],
  [1468, 403.1464427],
  [1469, 403.1464427],
  [1470, 403.1464427],
  [1471, 403.1464427],
  [1472, 403.1464427],
  [1473, 403.1464427],
  [1474, 403.1464427],
  [1475, 403.1464427],
  [1476, 403.1464427],
  [1477, 403.1464427],
  [1478, 403.1464427],
  [1479, 403.1464427],
  [1480, 403.1464427],
  [1481, 403.1464427],
  [1482, 403.1464427],
  [1483, 403.1464427],
  [1484, 403.1464427],
  [1485, 403.1464427],
  [1486, 403.1464427],
  [1487, 403.1464427],
  [1488, 403.1464427],
  [1489, 403.1464427],
  [1490, 403.1464427],
  [1491, 403.1464427],
  [1492, 403.1464427],
  [1493, 403.1464427],
  [1494, 403.1464427],
  [1495, 403.1464427],
  [1496, 403.1464427],
  [1497, 403.1464427],
  [1498, 403.1464427],
  [1499, 403.1464427],
  [1500, 403.1464427],
  [1501, 403.1464427],
  [1502, 403.1464427],
  [1503, 403.1464427],
  [1504, 403.1464427],
  [1505, 403.1464427],
  [1506, 403.1464427],
  [1507, 403.1464427],
  [1508, 403.1464427],
  [1509, 403.1464427],
  [1510, 403.1464427],
  [1511, 403.1464427],
  [1512, 403.1464427],
  [1513, 403.1464427],
  [1514, 403.1464427],
  [1515, 403.1464427],
  [1516, 403.1464427],
  [1517, 403.1464427],
  [1518, 403.1464427],
  [1519, 403.1464427],
  [1520, 403.1464427],
  [1521, 403.1464427],
  [1522, 403.1464427],
  [1523, 403.1464427],
  [1524, 403.1464427],
  [1525, 403.1464427],
  [1526, 403.1464427],
  [1527, 403.1464427],
  [1528, 403.1464427],
  [1529, 403.1464427],
  [1530, 403.1464427],
  [1531, 403.1464427],
  [1532, 403.1464427],
  [1533, 403.1464427],
  [1534, 403.1464427],
  [1535, 403.1464427],
  [1536, 403.1464427],
  [1537, 403.1464427],
  [1538, 403.1464427],
  [1539, 266.094703],
  [1540, 266.094703],
  [1541, 266.094703],
  [1542, 266.094703],
  [1543, 266.094703],
  [1544, 266.094703],
  [1545, 266.094703],
  [1546, 266.094703],
  [1547, 266.094703],
  [1548, 266.094703],
  [1549, 266.094703],
  [1550, 266.094703],
  [1551, 266.094703],
  [1552, 266.094703],
  [1553, 266.094703],
  [1554, 266.094703],
  [1555, 266.094703],
  [1556, 266.094703],
  [1557, 266.094703],
  [1558, 266.094703],
  [1559, 266.094703],
  [1560, 266.094703],
  [1561, 266.094703],
  [1562, 266.094703],
  [1563, 266.094703],
  [1564, 266.094703],
  [1565, 266.094703],
  [1566, 266.094703],
  [1567, 266.094703],
  [1568, 266.094703],
  [1569, 266.094703],
  [1570, 266.094703],
  [1571, 266.094703],
  [1572, 266.094703],
  [1573, 266.094703],
  [1574, 266.094703],
  [1575, 266.094703],
  [1576, 266.094703],
  [1577, 266.094703],
  [1578, 266.094703],
  [1579, 266.094703],
  [1580, 266.094703],
  [1581, 266.094703],
  [1582, 266.094703],
  [1583, 266.094703],
  [1584, 266.094703],
  [1585, 266.094703],
  [1586, 266.094703],
  [1587, 266.094703],
  [1588, 266.094703],
  [1589, 266.094703],
  [1590, 266.094703],
  [1591, 266.094703],
  [1592, 266.094703],
  [1593, 266.094703],
  [1594, 266.094703],
  [1595, 266.094703],
  [1596, 266.094703],
  [1597, 266.094703],
  [1598, 266.094703],
  [1599, 266.094703],
  [1600, 266.094703],
  [1601, 266.094703],
  [1602, 266.094703],
  [1603, 266.094703],
  [1604, 266.094703],
  [1605, 266.094703],
  [1606, 266.094703],
  [1607, 266.094703],
  [1608, 266.094703],
  [1609, 266.094703],
  [1610, 266.094703],
  [1611, 266.094703],
  [1612, 266.094703],
  [1613, 266.094703],
  [1614, 266.094703],
  [1615, 266.094703],
  [1616, 266.094703],
  [1617, 266.094703],
  [1618, 266.094703],
  [1619, 266.094703],
  [1620, 266.094703],
  [1621, 266.094703],
  [1622, 266.094703],
  [1623, 266.094703],
  [1624, 266.094703],
  [1625, 266.094703],
  [1626, 266.094703],
  [1627, 266.094703],
  [1628, 266.094703],
  [1629, 266.094703],
  [1630, 266.094703],
  [1631, 266.094703],
  [1632, 266.094703],
  [1633, 266.094703],
  [1634, 266.094703],
  [1635, 266.094703],
  [1636, 266.094703],
  [1637, 266.094703],
  [1638, 266.094703],
  [1639, 266.094703],
  [1640, 266.094703],
  [1641, 266.094703],
  [1642, 266.094703],
  [1643, 266.094703],
  [1644, 266.094703],
  [1645, 266.094703],
  [1646, 266.094703],
  [1647, 266.094703],
  [1648, 266.094703],
  [1649, 266.094703],
  [1650, 266.094703],
  [1651, 266.094703],
  [1652, 266.094703],
  [1653, 266.094703],
  [1654, 266.094703],
  [1655, 266.094703],
  [1656, 266.094703],
  [1657, 266.094703],
  [1658, 266.094703],
  [1659, 266.094703],
  [1660, 266.094703],
  [1661, 266.094703],
  [1662, 266.094703],
  [1663, 266.094703],
  [1664, 266.094703],
  [1665, 266.094703],
  [1666, 266.094703],
  [1667, 266.094703],
  [1668, 266.094703],
  [1669, 266.094703],
  [1670, 266.094703],
  [1671, 266.094703],
  [1672, 266.094703],
  [1673, 266.094703],
  [1674, 266.094703],
  [1675, 266.094703],
  [1676, 266.094703],
  [1677, 266.094703],
  [1678, 266.094703],
  [1679, 266.094703],
  [1680, 266.094703],
  [1681, 266.094703],
  [1682, 266.094703],
  [1683, 266.094703],
  [1684, 266.094703],
  [1685, 266.094703],
  [1686, 266.094703],
  [1687, 173.873918],
  [1688, 173.873918],
  [1689, 173.873918],
  [1690, 173.873918],
  [1691, 173.873918],
  [1692, 173.873918],
  [1693, 173.873918],
  [1694, 173.873918],
  [1695, 173.873918],
  [1696, 173.873918],
  [1697, 173.873918],
  [1698, 173.873918],
  [1699, 173.873918],
  [1700, 173.873918],
  [1701, 173.873918],
  [1702, 173.873918],
  [1703, 173.873918],
  [1704, 173.873918],
  [1705, 173.873918],
  [1706, 173.873918],
  [1707, 173.873918],
  [1708, 173.873918],
  [1709, 173.873918],
  [1710, 173.873918],
  [1711, 173.873918],
  [1712, 173.873918],
  [1713, 173.873918],
  [1714, 173.873918],
  [1715, 173.873918],
  [1716, 173.873918],
  [1717, 173.873918],
  [1718, 173.873918],
  [1719, 173.873918],
  [1720, 173.873918],
  [1721, 173.873918],
  [1722, 173.873918],
  [1723, 173.873918],
  [1724, 173.873918],
  [1725, 173.873918],
  [1726, 173.873918],
  [1727, 173.873918],
  [1728, 173.873918],
  [1729, 173.873918],
  [1730, 173.873918],
  [1731, 173.873918],
  [1732, 173.873918],
  [1733, 173.873918],
  [1734, 173.873918],
  [1735, 173.873918],
  [1736, 173.873918],
  [1737, 173.873918],
  [1738, 173.873918],
  [1739, 173.873918],
  [1740, 173.873918],
  [1741, 173.873918],
  [1742, 173.873918],
  [1743, 173.873918],
  [1744, 173.873918],
  [1745, 173.873918],
  [1746, 173.873918],
  [1747, 173.873918],
  [1748, 173.873918],
  [1749, 173.873918],
  [1750, 173.873918],
  [1751, 173.873918],
  [1752, 173.873918],
  [1753, 173.873918],
  [1754, 173.873918],
  [1755, 173.873918],
  [1756, 173.873918],
  [1757, 173.873918],
  [1758, 173.873918],
  [1759, 173.873918],
  [1760, 173.873918],
  [1761, 173.873918],
  [1762, 173.873918],
  [1763, 173.873918],
  [1764, 173.873918],
  [1765, 173.873918],
  [1766, 173.873918],
  [1767, 173.873918],
  [1768, 173.873918],
  [1769, 173.873918],
  [1770, 173.873918],
  [1771, 173.873918],
  [1772, 173.873918],
  [1773, 173.873918],
  [1774, 173.873918],
  [1775, 173.873918],
  [1776, 173.873918],
  [1777, 173.873918],
  [1778, 173.873918],
  [1779, 173.873918],
  [1780, 173.873918],
  [1781, 173.873918],
  [1782, 173.873918],
  [1783, 173.873918],
  [1784, 173.873918],
  [1785, 173.873918],
  [1786, 173.873918],
  [1787, 173.873918],
  [1788, 173.873918],
  [1789, 173.873918],
  [1790, 173.873918],
  [1791, 173.873918],
  [1792, 173.873918],
  [1793, 173.873918],
  [1794, 173.873918],
  [1795, 173.873918],
  [1796, 173.873918],
  [1797, 173.873918],
  [1798, 173.873918],
  [1799, 173.873918],
  [1800, 173.873918],
  [1801, 173.873918],
  [1802, 173.873918],
  [1803, 173.873918],
  [1804, 173.873918],
  [1805, 173.873918],
  [1806, 173.873918],
  [1807, 173.873918],
  [1808, 173.873918],
  [1809, 173.873918],
  [1810, 173.873918],
  [1811, 173.873918],
  [1812, 173.873918],
  [1813, 173.873918],
  [1814, 173.873918],
  [1815, 173.873918],
  [1816, 173.873918],
  [1817, 173.873918],
  [1818, 173.873918],
  [1819, 173.873918],
  [1820, 173.873918],
  [1821, 173.873918],
  [1822, 173.873918],
  [1823, 173.873918],
  [1824, 173.873918],
]
