import NavbarBM from './components/Navbar/NavbarBM'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ClaimView from './views/ClaimView/ClaimView'
import Crypto from './components/Crypto/Crypto'
import CheckNetwork from './components/CheckNetwork/CheckNetwork'

import './App.css'
import { RecoilRoot } from 'recoil'

export default function App() {
  return (
    <RecoilRoot>
      <Crypto>
        <CheckNetwork>
          <NavbarBM />
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<ClaimView />}>
                <Route index element={<ClaimView />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CheckNetwork>
      </Crypto>
    </RecoilRoot>
  )
}
