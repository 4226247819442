import React, { useState, useEffect, Suspense, useMemo } from 'react'
import {
  Button,
  Row,
  Card,
  Container,
  Nav,
  NavDropdown,
  Carousel,
  Col,
} from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import CardBM from '../../components/CardBM/CardBM'
import { Animated } from 'react-animated-css'
import { dailyEmmissions } from '../../data/dailyEmmissions'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  walletAddressShortSelector,
  cryptoFunctionsAtom,
  contractStateAtom,
  walletAddressAtom,
  userTermsAtoms,
  availableOathAtom,
  claimedOathAtom,
} from '../../recoil/atoms'

import { dpLevels, lgeEnd, oath } from '../../general/variables'
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton'
import PlayingCard from '../../components/PlayingCard/PlayingCard'
import RangeSelector from '../../components/RangeSelector/RangeSelector'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

const styles = {
  BMRow: {
    color: 'white',
    margin: 'auto',
    marginBottom: '25px',
    backgroundColor: 'rgba(14,14,14,0)',
    textAlign: 'center',
    alignItems: 'center',
  },
  BMCol: {
    margin: 'auto',
  },
}

const options = {
  responsive: true,
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    yAxes: {
      ticks: {
        beginAtZero: true,
        color: 'white',
        fontSize: 12,
      },
    },
    xAxes: {
      ticks: {
        beginAtZero: true,
        color: 'white',
        fontSize: 12,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
      position: 'top',
    },
    title: {
      display: true,
      text: 'Daily OATH Emmissions',
    },
  },
}

const rangeOptions = [
  { label: '30', value: 30 },
  { label: '90', value: 90 },
  { label: '1Y', value: 365 },
  { label: '2Y', value: 730 },
  { label: '3Y', value: 1095 },
  { label: '4Y', value: 1460 },
]

function ClaimView({}) {
  const [crypto] = useRecoilState(cryptoFunctionsAtom)
  const [walletAddress] = useRecoilState(walletAddressAtom)
  const [userTerms] = useRecoilState(userTermsAtoms)
  const [graphData, setGraphData] = useState(null)
  const [claimed] = useRecoilState(claimedOathAtom)
  const [avilableOath] = useRecoilState(availableOathAtom)

  const walletAddressShort = useRecoilValue(walletAddressShortSelector)
  const [dayLimit, setDayLimit] = useState(1460)

  const copyTokenAddress = async () => {
    await navigator.clipboard.writeText(oath)
    alert('Text copied')
  }

  useEffect(async () => {
    let filtered = dailyEmmissions.filter((d) => d[0] <= dayLimit)

    let labels = filtered.map((d) => {
      let date = new Date(lgeEnd * 1000)
      date.setDate(date.getDate() + d[0])
      return date.toLocaleDateString()
    })
    let values = filtered.map((d) => d[1])

    var graphData = {
      labels,
      datasets: [
        {
          label: 'OATH Daily Daily Emmissions',
          data: values,
          borderColor: 'purple',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
        },
        // {
        //   label: 'Contract Calls',
        //   data: dataCalls.data.map((d) => d.value),
        //   borderColor: 'rgb(255, 255, 255)',
        //   backgroundColor: 'rgb(0, 0, 0)',
        // },
      ],
    }
    setGraphData(graphData)
  }, [dayLimit])

  // useEffect(() => {
  //   crypto.getUserTerms()
  // }, [walletAddress])

  function getOathLevel() {
    var max = 0
    for (let i = 0; i < dpLevels.length; i++) {
      if (userTerms.shares >= dpLevels[i].minOath) max = i
    }
    return max
  }

  let oathLevel = getOathLevel()
  let oathPerSecond = ((userTerms.shares * 8.27) / userTerms.termSec).toFixed(5)
  if (isNaN(oathPerSecond)) oathPerSecond = 0

  let oathPerDay = (
    ((userTerms.shares * 8.27) / userTerms.termSec) *
    86400
  ).toFixed(5)
  if (isNaN(oathPerDay)) oathPerDay = 0

  let tempRangeOptions = [
    ...rangeOptions,
    { label: 'You', value: userTerms.term },
  ]

  return (
    <Container
      style={{
        margin: 'auto',
      }}
      fluid
    >
      {/* <div style={{ textAlign: 'center', marginTop: '25px'}}>
        <img style={{ height: '5em' }} src={'/media/oathLogoTrans.png'} />
      </div> */}
      <Row
        style={{
          ...styles.BMRow,
          marginTop: 60,
        }}
        lg={12}
        md={12}
      >
        <Col>
          <Animated
            animationIn="rotateIn"
            animationOut="bounceOutRight"
            isVisible={true}
            animationInDelay={100}
            animationInDuration={2000}
          >
            <PlayingCard
              oathLevel={oathLevel}
              userTerms={userTerms}
              dpLevels={dpLevels}
              walletAddressShort={walletAddressShort}
              statTopLeftIm={'🕐'}
              statTopLeft={parseFloat(userTerms.term)}
              statTopRightIm={'/media/OATHMiniLogo.png'}
              statTopRight={parseFloat(userTerms.shares * 8.27).toFixed(3)}
              statActionIm={'/media/OATHMiniLogo.png'}
              availableOath={avilableOath}
              onClickConnectWallet={crypto.connectWallet}
              onClickAction={crypto.claimOath}
            />
          </Animated>
        </Col>

        {walletAddress && (
          <Col style={{ textAlign: 'center' }}>
            <Animated
              animationIn="fadeIn"
              animationOut="bounceOutRight"
              isVisible={true}
              animationInDelay={1000}
              animationInDuration={2000}
              style={{
                marginTop: '25px',
                backgroundColor: 'rgba(0,0,0,0.5)',
                border: '1px solid white',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <div style={{ textAlign: 'center' }}>
                <h4>Your Stats</h4>
              </div>
              <p>
                Your OATH is vested to you linearly every second across your
                vesting term.
              </p>

              <table style={{ textAlign: 'right', margin: 'auto' }}>
                <tbody>
                  <tr>
                    <td>LGE Purchase:</td>
                    <td style={{ textAlign: 'left', paddingLeft: 10 }}>
                      {userTerms.shares} shares ={' '}
                      {(userTerms.shares * 8.2703758).toFixed(3)} OATH
                    </td>
                  </tr>
                  <tr>
                    <td>OATH/Sec:</td>
                    <td style={{ textAlign: 'left', paddingLeft: 10 }}>
                      {oathPerSecond}
                    </td>
                  </tr>
                  <tr>
                    <td>OATH/Day:</td>
                    <td style={{ textAlign: 'left', paddingLeft: 10 }}>
                      {oathPerDay}
                    </td>
                  </tr>
                  <tr>
                    <td>Claimed OATH:</td>
                    <td style={{ textAlign: 'left', paddingLeft: 10 }}>
                      {parseFloat(claimed).toFixed(3)}
                    </td>
                  </tr>
                  <tr>
                    <td>Ready to Claim:</td>
                    <td style={{ textAlign: 'left', paddingLeft: 10 }}>
                      {avilableOath == 0
                        ? 'All your oath has been claimed!'
                        : avilableOath.toFixed(3)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Animated>

            {graphData && (
              <Animated
                animationIn="fadeIn"
                animationOut="bounceOutRight"
                isVisible={true}
                animationInDelay={1250}
                style={{
                  marginTop: '25px',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  border: '1px solid white',
                  borderRadius: '5px',
                  padding: '10px',
                }}
              >
                <Row
                  style={{
                    ...styles.BMRow,
                  }}
                >
                  <Col>
                    <h4>Total Daily OATH Emissions</h4>
                    <Row>
                      <RangeSelector
                        style={{ margin: '0px auto' }}
                        options={tempRangeOptions}
                        onSelected={setDayLimit}
                      />
                    </Row>
                    <Row>
                      <Line options={options} data={graphData} />
                    </Row>
                    <p>
                      OATH Token Address:{' '}
                      <a
                        target="_blank"
                        href="https://ftmscan.com/address/0x21Ada0D2aC28C3A5Fa3cD2eE30882dA8812279B6#code"
                      >
                        {String(
                          '0x21Ada0D2aC28C3A5Fa3cD2eE30882dA8812279B6',
                        ).substring(0, 6) +
                          '...' +
                          String(
                            '0x21Ada0D2aC28C3A5Fa3cD2eE30882dA8812279B6',
                          ).substring(38)}
                      </a>{' '}
                      {/* <a className="Selectable" onClick={copyTokenAddress}>
                        <img
                          src="/media/copy.png"
                          alt="Copy"
                          width="25px"
                        />
                      </a>
                      <a
                        className="Selectable"
                        target="_blank"
                        href="https://ftmscan.com/address/0x21ada0d2ac28c3a5fa3cd2ee30882da8812279b6#code"
                      >
                        <img src="/media/link.png" width="25px" alt="Link" />
                      </a> */}
                    </p>
                  </Col>
                </Row>
              </Animated>
            )}
          </Col>
        )}
      </Row>
    </Container>
  )
}

export default ClaimView
