import React, { useEffect, useState } from 'react'

import { useRecoilState } from 'recoil'

import {
  walletAddressAtom,
  batchPriceAtom,
  statusAtom,
  cryptoFunctionsAtom,
  correctNetworkAtom,
  contractStateAtom,
  userTermsAtoms,
  availableOathAtom,
  claimedOathAtom,
} from '../../recoil/atoms'

import { ethers } from 'ethers'
import { lgeAddress, oath, lgeAbi, wFtmAbi } from '../../general/variables'

const FANTOM_NETWORK_ID = '250'
let LGE
let OATH
let signer
var provider

function Crypto({ children }) {
  const [, setBuyReceipt] = useState(null)
  const [userTerms, setUserTerms] = useRecoilState(userTermsAtoms)
  const [setIsDev] = useState(false)
  const [,] = useRecoilState(batchPriceAtom)
  const [, setContractState] = useRecoilState(contractStateAtom)
  const [correctNetwork, setCorrectNetwork] = useRecoilState(correctNetworkAtom)
  const [walletAddress, setWalletAddress] = useRecoilState(walletAddressAtom)
  const [, setStatus] = useRecoilState(statusAtom)
  const [, setCryptoFunctions] = useRecoilState(cryptoFunctionsAtom)
  const [claimed, setClaimed] = useRecoilState(claimedOathAtom)
  const [, setAvailableOath] = useRecoilState(availableOathAtom)

  useEffect(() => {
    if (correctNetwork) {
      initializeEthers()
      getCurrentWalletConnected()
      setInterval(async () => {
        // await getContractState()
        await getUserTerms()
      }, 5000)
    }
  }, [correctNetwork])

  useEffect(() => {
    checkCorrectNetwork()
    setCryptoFunctions({
      connectWallet: connectWalletPressed,
      connectToFantomNetwork: connectToFantomNetwork,
      getUserTerms: getUserTerms,
      claimOath: claimOath,
      getEndTime: getEndTime,
    })
  }, [])

  useEffect(async () => {
    if (walletAddress) {
      getUserTerms()
    }
  }, [walletAddress])

  useEffect(async () => {
    if (walletAddress) {
      await getAvailableOath()
    }
  }, [userTerms])

  async function initCrypto() {
    await getUserTerms()
    addWalletListener()
  }

  const connectToFantomNetwork = async () => {
    try {
      if (!window.ethereum) {
        alert(
          'No wallet found. Please ensure your browser has the Metamask extension installed or try using the Metamask mobile app browser.',
        )
        throw new Error('No crypto wallet found')
      }
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: `0x${Number(FANTOM_NETWORK_ID).toString(16)}`,
            chainName: 'Fantom Opera',
            nativeCurrency: {
              name: 'Fantom',
              symbol: 'FTM',
              decimals: 18,
            },
            rpcUrls: ['https://rpc.ftm.tools/'],
            blockExplorerUrls: ['https://ftmscan.com/'],
          },
        ],
      })
      checkCorrectNetwork()
    } catch (err) {}
  }

  const connectWalletPressed = async () => {
    initCrypto()
    await connectWallet()
  }

  const initializeEthers = async () => {
    if (!window.ethereum) {
      alert(
        'No wallet found. You will not be able to use this site without one. Please ensure your browser has the Metamask extension installed or try using the Metamask mobile app browser.',
      )
      throw new Error('No crypto wallet found')
    }

    provider = new ethers.providers.Web3Provider(window.ethereum)
    provider.on('network', (newNetwork, oldNetwork) => {
      if (oldNetwork) {
        window.location.reload()
        initializeEthers()
        initCrypto()
      }
    })

    try {
      if (!correctNetwork) return
      signer = provider.getSigner(0)
      const addr = await signer.getAddress()
      const checkNet = window.ethereum.networkVersion === FANTOM_NETWORK_ID
      OATH = new ethers.Contract(oath, wFtmAbi, provider.getSigner(0))
      LGE = new ethers.Contract(lgeAddress, lgeAbi, provider.getSigner(0))
    } catch (error) {
      return
    }
  }

  // Checks if wallet is connected to the correct network
  const checkCorrectNetwork = async () => {
    const { ethereum } = window
    let chainId = await ethereum.request({ method: 'eth_chainId' })
    const rinkebyChainId = '0x4'
    const fantomChainId = `0x${Number(FANTOM_NETWORK_ID).toString(16)}`
    const devChainId = 31337
    const localhostChainId = `0x${Number(devChainId).toString(16)}`
    if (chainId == devChainId) {
      setIsDev(true)
    }
    if (
      chainId !== rinkebyChainId &&
      chainId !== localhostChainId &&
      chainId !== fantomChainId
    ) {
      setCorrectNetwork(false)
    } else {
      setCorrectNetwork(true)
    }
  }

  const connectWallet = async () => {
    try {
      const addressArray = await provider.send('eth_requestAccounts', [])
      setWalletAddress(addressArray[0])
    } catch (err) {
      console.error('connectWallet error: ' + err.message)
    }
  }

  const claimOath = async () => {
    try {
      let tx = await LGE.claim()
      let receipt = await tx.wait()
      setBuyReceipt(receipt)
      await getAvailableOath()
    } catch (err) {
      console.error('claimOath error: ' + err.message)
    }
  }

  const getEndTime = async () => {
    try {
      var end = await LGE.end()
      return end.toNumber()
    } catch (err) {
      console.error('getEndTime error: ' + err.message)
    }
  }
  const getAvailableOath = async () => {
    try {
      let endTime = 1645729430
      let amountClaimed = await getClaimed()
      let pending = 0
      let userTotalOath = userTerms.shares * 8.2703758
      if (amountClaimed >= userTotalOath) {
        setAvailableOath(0)
        return
      }

      if (userTerms.shares > 0 && userTerms.termSec > 0) {
        let now = Date.now() / 1000
        let minTime = Math.min(now, endTime + userTerms.termSec)
        let deltaTime = minTime - endTime
        let oathPerSecond =
          (userTerms.shares * (80000000 / 9673079)) / userTerms.termSec
        let rewardDebt = deltaTime * oathPerSecond
        pending = rewardDebt - amountClaimed
        if (isNaN(pending)) pending = 0
      }

      setAvailableOath(pending)
    } catch (err) {
      console.error('getClaimed error: ' + err.message)
    }
  }

  const getClaimed = async () => {
    try {
      const addressArray = await provider.send('eth_requestAccounts', [])
      const walletAddress = addressArray[0] //await getCurrentWalletConnected()
      var claimed = await LGE.claimed(walletAddress)
      claimed = ethers.utils.formatEther(claimed)
      claimed = parseFloat(claimed).toFixed(3)
      setClaimed(claimed)
      return claimed
    } catch (err) {
      console.error('getClaimed error: ' + err.message)
    }
  }

  const getUserTerms = async () => {
    try {
      const addressArray = await provider.send('eth_requestAccounts', [])
      const walletAddress = addressArray[0] //await getCurrentWalletConnected()
      var terms = await LGE.terms(walletAddress)
      let userShares = terms[0].toString()
      let userTermSec = terms[1].toNumber()
      userTermSec = userTermSec.toFixed(5)
      let userTerm = terms[1].div(86400).toNumber()
      setUserTerms({
        shares: userShares,
        term: userTerm,
        termSec: userTermSec,
      })
      return {
        shares: userShares,
        term: userTerm,
        termSec: userTermSec,
      }
    } catch (err) {
      console.error('getUserTerms error: ' + err.message)
    }
  }

  const getContractState = async () => {
    try {
      var oathAddress = await LGE.oath()
      var raised = await LGE.raised()
      let shareSupply = await LGE.shareSupply()

      let defaultTerm = await LGE.defaultTerm()
      let defaultPrice = await LGE.defaultPrice()

      raised = ethers.utils.formatEther(raised)
      shareSupply = shareSupply.toNumber()
      defaultTerm = defaultTerm.div(86400).toNumber()
      defaultPrice = ethers.utils.formatEther(defaultPrice)
      // console.log('oathAddress', oathAddress)
      // console.log('raised', raised)
      // console.log('shareSupply', shareSupply)
      // console.log('defaultTerm', defaultTerm)
      // console.log('defaultPrice', defaultPrice)
      setContractState({
        oathAddress: oathAddress,
        raised: raised,
        shareSupply: shareSupply,
        defaultTerm: defaultTerm,
        defaultPrice: defaultPrice,
      })
    } catch (err) {
      console.error('getContractState error: ' + err.message)
    }
  }

  const getCurrentWalletConnected = async () => {
    try {
      const addressArray = await provider.send('eth_requestAccounts', [])
      setWalletAddress(addressArray[0])
      return addressArray[0]
    } catch (err) {
      console.error(err)
    }
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        console.log('wallet listener wallet: ', accounts)
        if (accounts.length > 0) {
          setWalletAddress(accounts[0])
        } else {
          setWalletAddress('')
        }
      })
    } else {
      setStatus(
        <p>
          {' '}
          🦊{' '}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>,
      )
    }
  }
  return children
}
export default Crypto
