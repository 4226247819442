import {
    Card,
  } from 'react-bootstrap'
  import 'bootstrap/dist/css/bootstrap.min.css'

  var style = {
    black: '#0e0e0e',
    white: '#fefefe',
    reaper: '#61445c',
    reaperDark: '#160712',
    reaperLight: '#eee9d9',
    reaperGrey: '#7c7b89',
  }
function CardBM(props) {
  return (
    <Card style={{ margin: "5px auto", width: '17rem', backgroundColor: style.black }}>
      <Card.Img variant="top" src={props.img} style={{ height: 200 }} />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ height: 100 }}>
          {props.text}
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default CardBM;