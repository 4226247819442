import { correctNetworkAtom, cryptoFunctionsAtom } from '../../recoil/atoms'
import { useRecoilState } from 'recoil'

export default function CheckNetwork({ children }) {
  const [correctNetwork] = useRecoilState(correctNetworkAtom)
  const [crypto] = useRecoilState(cryptoFunctionsAtom)

  if (correctNetwork) return children
  else
    return (
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          zIndex: 20,
          overflowY: 'hidden',
          color: 'white',
          backgroundColor: 'black',
        }}
      >
        <div
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'white',
            textAlign: 'center',
          }}
        >
          <img alt={"Oath Logo"} style={{ height: '60px' }} src={'/media/oathLogoTrans.png'} />
          <h6>Please connect to the Fantom Network to use this website.</h6>
          <button onClick={crypto.connectToFantomNetwork}>
            Connect to Fantom
          </button>
        </div>
      </div>
    )
}
