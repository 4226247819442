import { useState } from 'react'

export default function AnimatedButton(props) {
    const [hover, setHover] = useState(false)
    const [mouseDown, setMouseDown] = useState(false)
  
  return (
    <img
      src={
        hover && !mouseDown
          ? props.im1
          : props.im2
      }
      alt="connect-wallet1"
      onMouseEnter={() => setHover(false)}
      onMouseLeave={() => setHover(true)}
      onMouseDown={() => setMouseDown(true)}
      onMouseUp={() => setMouseDown(false)}
      {...props}
    />
  )
}
