import { atom, selector, useRecoilState, useRecoilValue } from 'recoil'

export const approvedSpendAtom = atom({
  key: 'approvedSpend',
  default: false,
})

export const claimedOathAtom = atom({
  key: 'claimedOath',
  default: 0,
})

export const availableOathAtom = atom({
  key: 'availableOath',
  default: 0,
})

export const loadingNFTsAtom = atom({
  key: 'loadingNFTs',
  default: true,
})

export const userTermsAtoms = atom({
  key: 'userTerms',
  default: {
    shares: 0,
    term: 0,
    termSec: 0,
  },
})

export const walletAddressAtom = atom({
  key: 'walletAddress',
  default: '',
})

export const contractStateAtom = atom({
  key: 'contractState',
  default: {
    oathAddress: '0x0',
    raised: 0,
    defaultTerm: 0,
    defaultPrice: 0,
  },
})
export const batchPriceAtom = atom({
  key: 'batchPrice',
  default: 0,
})
export const allocationNFTsAtom = atom({
  key: 'allocationNFTs',
  default: [],
})
export const correctNetworkAtom = atom({
  key: 'correctNetwork',
  default: false,
})
export const contractAddressAtom = atom({
  key: 'contractAddress',
  default: '',
})
export const ownerAddressAtom = atom({
  key: 'ownerAddress',
  default: '',
})
export const statusAtom = atom({
  key: 'status',
  default: '',
})

export const cryptoFunctionsAtom = atom({
  key: 'cryptoFunctions',
  default: {
    connectWallet: null,
    withdraw: null,
    mint: null,
    toggleSale: null,
  },
})

export const walletAddressShortSelector = selector({
  key: 'walletAddressShort', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const add = get(walletAddressAtom)
    return String(add).substring(0, 6) + '...' + String(add).substring(38)
  },
})
