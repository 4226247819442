import React from 'react'
import AnimatedButton from '../../components/AnimatedButton/AnimatedButton'

export default function PlayingCard({
  oathLevel,
  userTerms,
  dpLevels,
  walletAddressShort,
  statTopLeftIm,
  statTopLeft,
  statTopRightIm,
  statTopRight,
  statActionIm,
  availableOath,
  onClickConnectWallet,
  onClickAction,
}) {
  return (
    <div
      className={userTerms.term > 729 ? 'fire' : ''}
      style={{
        position: 'relative',
        margin: '0px auto auto auto',
        border: '3px solid black',
        animationDelay: '1.3s',
        backgroundColor: 'gray',
        maxWidth: '300px',
        borderRadius: '5px',
        width: '20em',
        height: '30.00em',
        backgroundImage: "url('/media/playing-card-background.png')",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        boxShadow: dpLevels[oathLevel].boxShadow,
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 5,
          left: 5,
        }}
      >
        {statTopLeftIm} {statTopLeft}d
      </div>
      <div
        style={{
          position: 'absolute',
          top: 35,
          left: '50%',
          transform: 'translate(-50%,0%)',
        }}
      >
        {walletAddressShort}
      </div>

      <div
        style={{
          position: 'absolute',
          top: 5,
          right: 5,
        }}
      >
        <img style={{ height: '30px' }} src={statTopRightIm} /> {statTopRight}
      </div>
      <div
        id={'DisplayPicture'}
        style={{
          zIndex: 3,
          backgroundImage: dpLevels[oathLevel].glow
            ? "url('/media/starsBG.gif')"
            : 'none',
        }}
      >
        <div>
          <img
            src={dpLevels[oathLevel].image}
            alt={'Oath Level Im'}
            className="floating"
            style={{
              maxHeight: dpLevels[oathLevel].maxHeight,
              marginBottom: dpLevels[oathLevel].marginBottom,
              // bottom: dpLevels[oathLevel].bottom,
            }}
          />
        </div>
      </div>
      <table
        style={{
          margin: 'auto auto auto auto',
          width: '80%',
          padding: '0px',
          textAlign: 'right',
          display: 'table',
          position: 'absolute',
          bottom: '60px',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 4,
        }}
      >
        <tbody>
          <tr>
            <td style={{ textAlign: 'center', paddingLeft: 10 }}></td>
          </tr>
        </tbody>
      </table>
      {walletAddressShort ? (
        <>
          <div
            style={{
              margin: 'auto auto auto auto',
              width: '80%',
              padding: '0px',
              textAlign: 'center',
              display: 'table',
              position: 'absolute',
              bottom: '60px',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 4,
            }}
          >
            <img style={{ height: '30px' }} src={statActionIm} />
            {availableOath == 0 ? (
              'Fully Claimed'
            ) : (
              <>{availableOath.toFixed(3)}</>
            )}
          </div>
          {availableOath != 0 && (
            <AnimatedButton
              className="ClaimButton"
              im2={'/media/button-claimoath.png'}
              im1={'/media/button-claimoath-pressed.png'}
              onClick={onClickAction}
              style={{
                width: '60%',
                margin: '5px auto',
                position: 'absolute',
                bottom: '0px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 4,
                cursor: 'pointer',
              }}
            />
          )}
        </>
      ) : (
        <AnimatedButton
          onClick={onClickConnectWallet}
          im2={'/media/button-connectwallet-1.png'}
          im1={'/media/button-connectwallet-2.png'}
          style={{
            width: '60%',
            margin: '5px auto',
            position: 'absolute',
            bottom: '0px',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 4,
            cursor: 'pointer',
          }}
        />
      )}
    </div>
  )
}
