import React, { useEffect, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import axios from 'axios'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  walletAddressAtom,
  walletAddressShortSelector,
  cryptoFunctionsAtom,
} from '../../recoil/atoms'

import AnimatedButton from '../AnimatedButton/AnimatedButton'

const cgSimpleApiURL = 'https://api.coingecko.com/api/v3/simple/'

const getOathPrice = async () => {
  const axGet = await axios
    .get(cgSimpleApiURL + `price?ids=oath&vs_currencies=usd`)
    .then((res) => {
      return res.data['oath'].usd
    })
  return axGet
}

const NavbarBM = (props) => {
  const [walletAddress] = useRecoilState(walletAddressAtom)
  const walletAddressShort = useRecoilValue(walletAddressShortSelector)
  const [crypto] = useRecoilState(cryptoFunctionsAtom)

  const [oathPrice, setOathPrice] = useState(0)

  useEffect(() => {
    setTimeout(() => {
      getOathPrice().then((res) => {
        if (res != oathPrice) setOathPrice(res)
      })
    }, 15000)
    getOathPrice().then((res) => setOathPrice(res))
  }, [])

  return (
    <Navbar
      style={{
        color: 'white',
        backgroundColor: 'rgba(0,0,0,0.5)',
        borderBottom: '1px solid white',
        padding: 10,
      }}
      expand="lg"
      variant="dark"
    >
      {props.data}
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Brand href="/">
        <img style={{ height: '45px' }} src={'/media/oathLogoTrans.png'} />
      </Navbar.Brand>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/">Claim</Nav.Link>
        </Nav>
        <Nav>
          {walletAddress && (
            <Nav.Link
              href={'https://ftmscan.com/address/' + walletAddress}
              target="_blank"
            >
              {walletAddressShort}
            </Nav.Link>
          )}
          <Nav.Link
            href="https://www.coingecko.com/en/coins/oath"
            target="_blank"
          >
            <img height="30" src="/media/OATHMiniLogo.png"></img> $
            {oathPrice.toFixed(2)}
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
export default React.memo(NavbarBM)
