import React from 'react'

export default function RangeSelector({ options, onSelected }) {
  function onBtnClicked(e) {
    onSelected(e.target.attributes['value'].value)
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      {options.map((op, i) => (
        <div
          key={i}
          className="RangeSelector"
          value={op.value}
          onClick={onBtnClicked}
        >
          {op.label}
        </div>
      ))}
    </div>
  )
}
